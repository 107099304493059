.campaignHeader {
  border-radius: 10px;
  background-color: rgba(127, 127, 127, 0.05);
  padding: 20px 24px;
  margin-block: 20px;
  display: grid;
  grid-template-columns: 23% 17% 17% 17% 26%;
}

.headerItem {
  color: #000000b3;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.rowWrapper {
  display: grid;
  grid-template-columns: 23% 17% 17% 17% 26%;
  padding: 32px 24px;
  margin-block: 12px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.rowWrapper > * {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.rowName {
  font-size: 16px;
  justify-content: start;
}

.rowPlatform,
.rowVisibility,
.rowDate {
  font-size: 14px;
}

.rowPlatform {
  gap: 6px;
}

.rowOps {
  gap: 0;
  justify-content: flex-end;
}

.statusTag {
  padding: 4px 0 !important;
  border-radius: 60px !important;
  width: 110px;
}
