.container {
  /* display: grid; */
  padding: 20px;
  min-height: 285px;
  /* gap: 20px; */
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
  border-radius: 8px !important;
  /* justify-content: flex-start; */
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #7f7f7f;
}

.metric {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
}

.emptyText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
}
