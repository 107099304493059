.navContainer {
  display: flex;
  height: 8.3vh;
  padding: 0px 64px;
  align-items: center;
  justify-content: center;

  border: 2px solid #f0f0f0;
  background: #ffffff;
}

.innerContainer {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.rightNav {
  display: flex;
}

.navItemContainer {
  display: flex;
  height: 100%;
  align-items: center;
}

.activeNavItem {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* color: #2075f3; */
  opacity: 1;
  padding: 9px 4px;
  /* border-bottom: 2px solid #2075f3; */
  border-bottom: 2px solid #080bf4;
  cursor: pointer;
  height: 100%;
  display: flex;
}

.navItem {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding: 9px 4px;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  display: flex;
}

.profile {
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  border-radius: 12px !important;
  padding: 8px !important;
  display: flex !important;
}

.alert {
  background: rgb(83, 58, 245);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  gap: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.kycPending {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  gap: 6px;
  background-color: #f4f4f4;
  border: 1px solid rgba(1, 1, 1, 0.12);

  backdrop-filter: blur(6px);
}
