.container {
  margin: 20px;
  display: grid;
  gap: 20px;
}

.reportContainer {
  border: 1px solid #e8e8e8;
  /* filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25)); */
  border-radius: 8px;

  display: grid;
  gap: 20px;
  padding: 20px;
}
