.container {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 12px 30px;
  display: flex;
  justify-content: space-between;
  margin: 0 -44px;
}

.button:hover {
  background-color: #533af5 !important;
  color: #ffffff !important;
}
