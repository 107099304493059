.extra {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.container {
  display: flex;
  flex-direction: column;
}

.banner {
  height: 220px;
  width: 100%;
  background-color: #d9d9d9;
  position: relative;
}

.bannerImageContainer {
  width: 100%;
  height: 100%;
}
.delete {
  position: absolute;
  display: none;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.65);
  color: white;
}

.delete > div {
  display: flex;
  gap: 3px;
  cursor: pointer;
}

.bannerImageContainer:hover .delete {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 21px 0;
  border-bottom: 1px solid #000000;
}

.header > div {
  display: flex;
  align-items: center;
  gap: 13px;
}

.header > div > img {
  width: 84px;
  height: 84px;
  object-fit: cover;
  border-radius: 10px;
}

.header > div > div {
  display: flex;
  flex-direction: column;
}

.campaignName {
  font-weight: 600;
  font-size: 16px;
  color: #09121f;
}

.brandName {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}

.header > img {
  width: 38px;
  height: 39px;
}

.pocDetails {
  display: flex;
  flex-direction: column;
  padding: 21px 15px;
  border-bottom: 1px solid #000000;
  gap: 26px;
}

.pocDetails > div {
  display: flex;
}

.pocTitle {
  flex: 0.3;
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.4);
}

.pocValue {
  flex: 0.7;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.communityAndFollower {
  display: flex;
  flex-direction: column;
  padding: 21px 21px;
  border-bottom: 1px solid #000000;
  gap: 20px;
}

.community {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.community > p {
  font-weight: 700;
  font-size: 16px;
  color: #09121f;
}

.communities {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.communities > p {
  display: flex;
  align-items: center;
  padding: 2px 12px;
  background: rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 100px;
}

.follower {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.follower > p {
  font-weight: 700;
  font-size: 16px;
  color: #09121f;
}

.ranges {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.ranges > p {
  font-weight: 400;
  font-size: 14px;
  color: #09121f;
  text-transform: capitalize;
}

.productDetails {
  display: flex;
  flex-direction: column;
  padding: 21px 21px;
  border-bottom: 1px solid #000000;
  gap: 20px;
}

.productDetails > p {
  font-weight: 700;
  font-size: 16px;
  color: #09121f;
}

.products {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.product {
  display: flex;
}

.product > span {
  flex: 0.03;
}

.product > div {
  flex: 0.97;
  display: flex;
  flex-direction: column;
}

.product > div > p {
  font-weight: 400;
  font-size: 14px;
  color: #09121f;
}

.promotion > p {
  font-weight: 400;
  font-size: 14px;
  color: #09121f;
}

.deliverableDetails {
  display: flex;
  flex-direction: column;
  padding: 21px 21px;
  border-bottom: 1px solid #000000;
  gap: 20px;
}

.deliverableDetails > p {
  font-weight: 700;
  font-size: 16px;
  color: #09121f;
}

.deliverables {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.deliverable {
  display: flex;
}

.deliverable > span {
  flex: 0.03;
}

.deliverable > div {
  flex: 0.97;
  display: flex;
  flex-direction: column;
}

.deliverableName {
  font-weight: 500;
  font-size: 15px;
  color: #09121f;
}

.deliverableDescription {
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}

.productDescription {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}

.productDescription > span {
  flex: 0.03;
}

.productDescription > div {
  flex: 0.97;
  display: flex;
  flex-direction: column;
}

.repostImage {
  width: 92px;
  height: 92px;
  object-fit: cover;
  border-radius: 6px;
}

.repostImages {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.guidelinesDetails {
  display: flex;
  flex-direction: column;
  padding: 21px 21px;
  gap: 10px;
}

.guidelinesDetails > p {
  font-weight: 700;
  font-size: 16px;
  color: #09121f;
}

.guidelines {
  font-weight: 400;
  font-size: 14px;
  color: #09121f;
}
