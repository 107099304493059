.grid {
  display: grid;
  gap: 20px;
}

.title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
}

.desc {
  color: #7f7f7f;
  font-size: 12px;
}

.learnMore {
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.image {
  width: 200px;
  height: 283px;
  border-radius: 5px;
  object-fit: cover;
}

.instaContainer {
  display: flex;
  gap: 2px;
  margin-top: 10px;
  align-items: center;
}
.instaButton {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  text-decoration-line: underline;
}

.graphContainer {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 350px;
}

.graphLabel {
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.label {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.subLabel {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #7f7f7f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
