.container{
    background: #FFFFFF;
    border: 0.5px solid #D9D9D9;
    box-shadow: 0px 0px 8px rgba(10, 6, 18, 0.04);
    border-radius: 6px;
}

.tile{
    padding:12px 0;
}

.label{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
}

.value{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    
}