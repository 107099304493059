@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");

html {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1200px;
  overflow-x: auto;
  /* font-weight: 400; */
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .ant-modal-content {
  border-radius: 20px !important;
  border: "1px solid #8C8C8C" !important;
  border-radius: "24px" !important;
} */
/* carousel  */
.slides {
  position: relative;
}
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
}
.slick-prev {
  left: 2%;
  z-index: 500;
}
.slick-next {
  right: 2%;
  z-index: 500;
}

.ant-modal-content {
  border-radius: 8px;
  /* background: red; */
}

.ant-modal-header {
  border-radius: 8px 8px 0 0;
}

.rdw-dropdown-selectedtext {
  color: rgba(0, 0, 0, 0.85) !important;
}

.rdw-editor-main::-webkit-scrollbar {
  display: none;
}

.rdw-editor-main {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: auto !important;
}

.aa-Panel {
  position: fixed;
  z-index: 1000;
}

.aa-Form {
  border: none;
}

.flex-row {
  display: flex;

  justify-content: space-between;
  align-items: center;
}
.flex-gap-4 {
  display: flex;
  gap: 4px;
  align-items: center;
}

.ais-RefinementList-count {
  display: none !important;
}

.ant-form-item-extra {
  text-align: center;
}

.ant-btn.custom-button-hover:hover {
  background-color: #080bf4;
  color: #fff;
  border-color: #080bf4;
}

.ant-btn.published-button {
  background-color: #379b37;
  color: white;
  border-color: #379b37;
  pointer-events: none;
}

.ant-btn.published-button:hover {
  background-color: #379b37;
  color: white;
  border-color: #379b37;
}

.ant-btn.underlined-btn {
  padding: 0;
  height: auto;
}

.ant-btn.ant-btn.underlined-btn span {
  text-decoration: underline;
  font-weight: 700;
}

.ant-btn.ant-btn.underlined-btn.text-black span {
  color: #000;
}
.ant-btn.ant-btn.underlined-btn.text-blue span {
  color: #533af5;
}

.ant-btn.custom-option-button {
  padding: 0;
}

.custom-modal .ant-modal-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #848484;
}

.campaign-create-modal .ant-modal-close,
.custom-modal .ant-modal-close {
  top: 15px;
}

.ant-btn.btn {
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  font-family: Inter;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
}

.ant-btn.resend-otp-btn > span {
  text-decoration: underline;
}

.cccSelector .ant-select-selector {
  border-radius: 12px !important;
  padding: 8px 24px !important;
  height: 56px !important;
  background-color: #533af51a !important;
  color: #080bf4 !important;
}

.selectTransparent .ant-select-selector {
  border-radius: 4px !important;
  border-color: transparent !important;
  background-color: transparent !important;
}

.cccSelector .ant-select-selection-search-input {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 0 8px !important;
}

.cccSelector .ant-select-arrow {
  color: #080bf4 !important;
}

.cccSelect.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 48px !important;
  align-items: center !important;
}

.global-no-data {
  display: block;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  line-height: 24px;
  color: #aaa;
  padding: 20px 0;
  margin: 0 auto;
}
