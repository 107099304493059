.rowItem {
  width: 100%;
  border-top: 1px solid rgba(55, 53, 47, 0.09);
  /* border-bottom: 1px solid rgba(55, 53, 47, 0.09); */
}

.table {
  width: 100%;
  font-size: 13px;
  border-top: 1px solid rgba(55, 53, 47, 0.09);
  border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

.rowCell {
  padding: 8px 0;
  user-select: none;
  cursor: default;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.2;
  color: rgb(55, 53, 47);
}

.headCell {
  font-size: 12px;
  line-height: 16px;
  color: rgba(55, 53, 47, 0.65);
  white-space: nowrap;
}

.connected {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
  padding: 5px 10px;
  background: rgb(219, 237, 219);
  font-size: 10px;
}

.connectedLabel {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(57, 130, 0);
}
