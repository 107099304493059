.heading {
  color: #222;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.noteContainer {
  border-radius: 9px;
  background: #e5e5e5;
  padding: 17px 20px;
  margin-top: 15px;
}

.noteText {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 139.023%; /* 19.463px */
  display: flex;
  align-items: center;
  gap: 7px;
}

.text {
  color: #222;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block: 30px;
}

.link {
  color: rgba(0, 0, 0, 0.85);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.inputWrapper {
  margin-block: 10px 60px;
}
