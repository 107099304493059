.reachContainer {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}

.reach {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.graphContainer {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: grid;
  gap: 20px;
  position: relative;
}

.graphLabel {
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.optionsContainer {
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
