.container {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  overflow-x: hidden;
}

.rejectionHeading {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  background: #f5f5f5;
  padding: 7px 10px;
  color: rgba(0, 0, 0, 0.45);
}

.rejectionDesc {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
}

.cardHeading {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  /* border: 2px solid red; */
}

.creatorHeading {
  display: flex;
  gap: 16px;
}
.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
}

.metricCard {
  width: 80px;
}

.starIcon {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  cursor: pointer;
  flex-grow: 1;
}

.creatorImage {
  border-radius: 100px;
  background-color: aqua;
  width: 44px;
  height: 44px;
}

.panelDesc {
  color: #747474;
  padding: 16px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  background: #f2f4f7;
}

.panelHeader {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.panelButtonList {
  display: flex;
}

.carouselImage {
  width: 150px;
  height: 110px;
  border-radius: 8px;
}

.imageContainer {
  position: relative;
  width: 150px !important;
  height: 100px;
}

.carouselTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  position: absolute;
  bottom: 2px;
  z-index: 100;
  left: 10px;
}

.price {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* font-family: 'Roboto'; */
}
