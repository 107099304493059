.gridContainer {
  display: grid;
  gap: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25)); */
  border-radius: 8px;
  padding: 10px;
}

.reportGrid {
  border: none;
  filter: none;
  border-radius: 0;
  padding: 0;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #7f7f7f;
}

.titleGrey {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.5);
}

.modalGADesc {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.modalDescLight {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;

  font-weight: 300;
}

.profile {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 999px;
}
