.g10 {
  display: grid;
  gap: 10px;
}

.profile {
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.name {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-weight: 500;
}
