.campaignHeader {
  border-radius: 10px;
  display: grid;
  margin-block: 8px;
  grid-template-columns: 16.5% 16.5% 16.5% 16.5% 16.5% 16.5%;
}

.headerItem {
  color: #000000b3;
  font-size: 16px;
  font-weight: 600;
}

.noDataHeading {
  color: #808080;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
}

.noDataContent {
  color: #333;
  font-size: 14px;
  font-weight: 500;
}

.row {
  padding: 0 24px;
  display: grid;
  padding-block: 16px;
  /* grid-template-columns: 17.5% 22.5% 17.5% 42.5%; */
  grid-template-columns: 16.5% 16.5% 16.5% 16.5% 16.5% 16.5%;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}

.rowItem {
  color: rgba(0, 0, 0, 0.7);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rowItem.status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.status2 {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
  padding: 2px 5px;
}

.connected {
  background: rgb(219, 237, 219);
}

.pending {
  background: rgba(128, 128, 128, 0.1);
}

.inviteStatus {
  border-radius: 20px;
  padding: 10px 20px;
  max-width: 148px;
}

.accepted {
  background: rgba(83, 58, 245, 0.1);
}

.pending2 {
  background: rgba(117, 117, 117, 0.2);
}

.withdrawn {
  background: rgba(235, 63, 121, 0.2);
}

.profile {
  border-radius: 50px;
  width: 50px;
  height: 50px;
}
