.container {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(33, 48, 255, 0.25);
  border-radius: 8px;
}

.headerSection {
  display: grid;
  gap: 16px;
  padding: 20px;
  padding-bottom: 0;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  cursor: pointer;
  /* justify-content: space-between; */
}

.profilePhoto {
  width: 60px;
  height: 60px;
  border: 1px solid #2130ff;
  border-radius: 100px;
}

.metricValue {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.metricLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  margin: 0;
  margin-top: 2px;
  color: #747474;
}

.feetMetric {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700px;
}

.commercialValue {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.commercialLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}
