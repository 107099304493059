.siteTagPlus {
  background: #fff;
  border-style: dashed;
}
.editTag {
  user-select: none;
}
.tagInput {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
}
