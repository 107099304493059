.resend{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    cursor: pointer;
    color: #080BF4;
    margin-top: 2px;
}


.disabled{
    color:#595959;
    cursor: not-allowed;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-top: 2px;
}

.footerWrapper{
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.label{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #00000073
}

.space{
    display: flex;
    justify-content: space-between;
}

.paymentHeader {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
    padding-bottom: 7px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.paymentTile{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 16px;
}

.typoLight{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
}

.typoDark{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
}
.total{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.totalContainer{
    padding: 12px 16px;
    gap: 12px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: 0px 1px 9px rgba(33, 48, 255, 0.09);
    border-radius: 5px;
}

.taxesTypo{
    color: rgba(0, 0, 0, 0.45);
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
}
.lockImage{
    width: 87px; 
    margin: 0 auto;
    
}

.warningTitle{
    margin-top: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
}
.warningDesc{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
}

.caution{
    text-align: center;
    margin-bottom: 30px;
}

.proceed{
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    margin-left: 4px;
}