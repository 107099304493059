.modalTitle {
  font-weight: 500;
  font-size: 20px;
}

.activateText {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
}
