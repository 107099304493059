.active {
  padding: 2px 12px;
  background: #2130ff;
  border: 1px solid #2130ff;
  border-radius: 15px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.inActive {
  padding: 2px 12px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
}
