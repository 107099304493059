.gridContainer {
  display: grid;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25)); */
  border-radius: 8px;
  gap: 20px;
  padding: 20px;
  height: 176px;
  width: 236px;
  flex-shrink: 0;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* word-wrap: break-word;
  display: inline-block;
  width: 45%; */
  color: #000000;
}

.desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;

  /* word-wrap: break-word; */
  /* display: inline-block;*/
  /* width: 65%; */

  color: #7f7f7f;
}

.metric {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}
