.container {
  /* height: 91.7vh; */
  height: 100%;
}

.header {
  display: flex;
  gap: 12px;
  height: 8.3vh;
  padding: 16px 0 8px 20px;
  background: white;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-bottom: 2px solid #dddddd;
  align-items: center;
  /* border-radius: 10px 10px 0 0; */
}

.profile {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  cursor: pointer;
}

.cName {
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
}

.chats {
  /* overflow-y: scroll; */
  /* height: 60vh; */
  background: #ffffff;
  padding: 24px 16px;
  /* height: 74.1vh; */
}
