.selectTag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 10px;
  background: #533af5;
  border-radius: 39px;
  margin: 10px 8px;
  color: #ffffff;
}

.selectTagText {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.label {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  /* Web/BlackShaded */

  color: #0f0f0f;
}
