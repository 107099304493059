.card {
  padding: 8px 12px;
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 0px 8px rgba(10, 6, 18, 0.04);
  border-radius: 8px;
  height: 418px;
}

.statsTile {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 12px;
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
  display: flex;
  justify-content: space-between;
}

.category {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #8c8c8c;
}

.percent {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.requestContainer {
  background: #f0f0f0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 0px 8px rgba(10, 6, 18, 0.04);
  border-radius: 8px;
  padding: 20px 16px;
}

.requestHeading {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 8px;
  text-align: center;
}

.scrollableItem {
  max-height: 308px;
  overflow-y: auto;
}
