.campaignHeader {
  border-radius: 10px;
  display: grid;
  margin-block: 8px;
  grid-template-columns: 12.5% 19.5% 13.5% 19.5% 13.5% 21.5%;
}

.headerItem {
  color: #000000b3;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
}

/* .row:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
} */

.row {
  padding: 0 24px;
  display: grid;
  padding-block: 16px;
  /* grid-template-columns: 17.5% 22.5% 17.5% 42.5%; */
  grid-template-columns: 12.5% 19.5% 13.5% 19.5% 13.5% 21.5%;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}

.rowItem {
  color: rgba(0, 0, 0, 0.7);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rowItem.status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.status2 {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
  padding: 2px 5px;
}

.connected {
  background: rgb(219, 237, 219);
}

.pending {
  background: rgba(128, 128, 128, 0.1);
}

.inviteStatus {
  border-radius: 20px;
  padding: 10px 20px;
  max-width: 148px;
}

.accepted {
  background: rgba(83, 58, 245, 0.1);
}

.pending2 {
  background: rgba(117, 117, 117, 0.2);
}

.withdrawn {
  background: rgba(235, 63, 121, 0.2);
}

/* EMPTY SCREEN STYLES */

.noDataCard {
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: #533af5;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  padding: 100px 40px;
  color: #ffffff;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
}

.textContent {
  width: 450px;
  padding: 20px;
}

.textContent h1 {
  font-family: Inter;
  font-size: 44px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.textContent h2 {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-block: 20px;
}

.textContent p {
  margin-block: 20px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn {
  margin-block: 40px;
}
