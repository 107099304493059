.menuContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  /* padding-bottom: 10px;  */
  /* Adjust as needed to match your design */
}

/* Style the button within the container */
.customButton {
  align-self: flex-end;
  margin-bottom: 0; /* Remove button's default bottom margin */
}
