.main {
  overflow: auto;
  min-height: 100vh;
}

.main * {
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  justify-content: start;
  max-width: 100vw;
  width: 100%;
  padding: 0 64px;
  margin-block: 48px;
}

.header__title {
  margin: 0;
  font-weight: 500;
  font-size: 40px;
  line-height: 55px;
  flex: 1;
  text-align: center;
  color: #000000;
}

.container {
  width: 1068px;
  padding: 0 64px;
  margin-block: 48px;
  margin-inline: auto;
}

.tableHeader {
  background: #f5f5f5;
  box-shadow: inset 1px 0px 0px #d9d9d9, inset -1px 0px 0px #d9d9d9,
    inset 0px 1px 0px #d9d9d9, inset 0px -1px 0px #d9d9d9;
  border-radius: 10px;

  padding: 16px 10px 16px 44px;
  display: grid;
  grid-template-columns: 19.5% 19.5% 19.5% 19.5% 19.5% 2.5%;
}

.tableItem {
  letter-spacing: 0.065em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
}

.tableRowData {
  display: grid;
  grid-template-columns: 19.5% 19.5% 19.5% 19.5% 19.5% 2.5%;
  padding: 16px 10px 16px 44px;
  border-bottom: 1px solid #0f0f0f23;
}

.tableRowData p:first-child {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
}

.tableRowData p:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.tableRowData p:not(:first-child) {
  text-align: center;
  font-weight: 400;
}

.tableStatus {
  border-width: 1px;
  border-style: solid;
  padding: 2px 16px;
  border-color: black;
  width: max-content;
  margin: 0 auto;
  border-radius: 8px;
  height: 28px;
}
