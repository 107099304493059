.container {
  border: 2px solid transparent;
  cursor: pointer;
  border-radius: 8px;
  width: max-content;
}

.selected {
  border: 2px solid #533af5;
}

.imageContainer {
  object-fit: cover;
}

.image {
  object-fit: cover;
  border-radius: 7px;
  width: 136px;
  height: 190px;
}

.label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.8);
}

.selectedLabel {
  color: #533af5;
}

.thumbnail {
  border-radius: 4px;
  height: 150px;
  width: 220px;
}

.profilePic {
  height: 30px;
  width: 30px;
  border-radius: 100px;
}

.caption {
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 500;
  overflow: hidden;
  display: block;
  max-height: 4.4rem;
  -webkit-line-clamp: 2;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

.ytContainer {
  display: grid;
  padding: 10px;
}
