.span {
  color: #222;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.download {
  color: #533af5;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 139.023%; /* 19.463px */
}

.csvInputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 33px 28px;
  gap: 4px;

  height: 150px;

  background: #ffffff;
  /* Web/ThemeC */

  border: 2px dashed #808080;
  border-radius: 4px;
  cursor: pointer;
  width: 500px;
  margin: 0 auto;
}

.uploadButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4px;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  text-align: center;
  cursor: pointer;
  color: #676767;
}

.emailContainer {
  margin-top: 20px;
  border-radius: 9px;
  background: #fddd99;
  padding: 10px 15px;
}

.emailHeading {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.emailButton {
  border: 1px solid #808080 !important;
  color: #222 !important;
}
