.container {
  border-radius: 14px;
  border: 1px solid #533af5;
  background: #f7f7f7;
  padding: 20px 15px;
}

.heading {
  color: #533af5;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

.content {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.403px;
}

.note {
  color: #333;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.203px;
  text-transform: capitalize;
}

.link {
  color: #533af5;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160.023%; /* 22.403px */
  text-decoration-line: underline;
  /* text-transform: capitalize; */
}

.flex {
  display: flex;
  align-items: center;
}

.gapfull {
  justify-content: space-between;
}

.gap6 {
  gap: 10px;
}

.btn {
  border-radius: 50%;
  border: 0;
  outline: 0;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background-color: #e5e5e5;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
