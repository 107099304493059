.container {
  /* width: 820px; */
  /* max-height: 540px; */
  /* overflow-y: auto; */
  padding: 20px 24px;
  /* outline: 1px dashed red; */
  margin: 0px auto 20px;
}

.container::-webkit-scrollbar {
  width: 10px;
}

.planHeading {
  color: #999;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.planBox {
  margin-top: 38px;
}

.planCurrent {
  padding-inline: 20px;
  margin-bottom: 40px;
}

.planTitle {
  color: #222;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.planDescription {
  color: #222;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.otherPlanContainer {
  border-bottom: 1px solid rgba(117, 117, 117, 0.25);
  padding-bottom: 10px;
  padding-inline: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.otherPlansHeading {
  color: #222;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-block: 0;
}

.allPlans {
  display: flex;
  align-items: center;
  gap: 8px;
}

.allPlans .text {
  color: #808080;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.otherPlans {
  margin-top: 32px;
  padding-inline: 16px;
}

.allPlansContainer {
  border-bottom: 1px solid rgba(117, 117, 117, 0.25);
  padding-bottom: 10px;
  padding-inline: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 999;
}

.allPlansHeading {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-block: 0;
}

.faqsHeading {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(117, 117, 117, 0.25);
  padding-inline: 16px;
}

.accordian {
  padding-block: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(117, 117, 117, 0.25);
  padding-inline: 16px;
}

.acc_question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.acc_question p {
  color: #222;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.title {
  color: #222;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-block: 0;
  margin-bottom: 6px;
}

.price {
  color: #222;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
}

.curr {
  color: #808080;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 70px;
}

.perks {
  color: #808080;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.perkTile {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
