.container {
  border: 2px solid rgba(0, 0, 0, 0.45);
  cursor: pointer;
  border-radius: 8px;
  width: max-content;
}

.selected {
  border: 2px solid #533af5;
}

.imageContainer {
  object-fit: cover;
}

.image {
  object-fit: cover;
  border-radius: 7px;
  width: 136px;
  height: 190px;
}

.selectedLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1106f7;
}
