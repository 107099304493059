.inputContainer {
  background: #fafafa !important;
  /* border: 1.5px solid rgba(0, 0, 0, 0.45) !important; */
  border-radius: 3px !important;
  padding: 10px 32px 10px 24px !important;
  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 26px !important;
  color: #000000 !important;
}
.inputContainerTextArea {
  /* border: 1.5px solid rgba(0, 0, 0, 0.45) !important; */
  border-radius: 3px !important;
  /* padding: 10px 32px 10px 24px !important; */
  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 26px !important;
  color: #000000 !important;
}

.container {
  display: grid;
  gap: 4px;
}

.label {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #0f0f0f;
}

.subLabel {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0f0f0f;
}

.error {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: red;
}
