.sidebar {
  height: 100%;
  background: #eceef3 !important;
  padding: 16px;
}

.heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.primaryQuestion {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
}

.campaignVisibility {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.navs {
  background: "#ECEEF3";
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.options {
  display: flex;
  flex-direction: column;

  gap: 2px;
}

.option {
  background: rgba(255, 255, 255, 0.54);
  padding: 11px 16px;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  line-height: 24px;
}

.optionActive {
  color: #2075f3;
  font-weight: 500;
  padding: 11px 16px;
  background: rgba(255, 255, 255, 0.81);
  cursor: pointer;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  font-weight: 500;
}

.countActive {
  background: #2130ff;
  border-radius: 100px;
  padding: 0px 6px;
  color: #ffffff;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2em;
  height: 2em;
}

.count {
  background: #f0f0f0;
  border-radius: 100px;
  padding: 0px 6px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2em;
  height: 2em;
}

.campaignButton {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  padding: 4px 10px;
  /* transition: cubic-bezier(255, 255, 255, 0.54) !important; */
}

.campaignButton:hover {
  opacity: 0.8;
}

.selectTag {
  background: #f0f5ff;
  cursor: pointer;
  border: 1px solid #adc6ff;
  border-radius: 39px;
  padding: 10px 24px;
}

.selectTagText {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #2f54eb;
}
