.cloutflow {
  color: rgba(0, 0, 0, 0.7);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ads {
  color: rgba(0, 0, 0, 0.5);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.navBorder {
  display: flex;
  gap: 5px;
  align-items: center;
  border: 1px solid #d9d9d9;
  padding: 8px;
  border-radius: 12px;
}

.navContainer {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
}
