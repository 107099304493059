.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 6px;
}

.username {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.profilePic {
  width: 50px;
  height: 50px;
  border-radius: 999px;
  object-fit: cover;
}

.reportContainer {
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25)); */
  border-radius: 8px;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 20px;
}

.colLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  min-height: 40px;
  padding: 10px;
  color: rgba(0, 0, 0, 0.45);
  background-color: white;
  margin: -12px -8px;
  height: 60px;
  padding: 12px 8px;
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
}

.darkRow {
  background-color: #f7f7f7;
}
