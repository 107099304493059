.container {
  display: grid;
  gap: 30px;
}

.pic {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.username {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.8);
}

.priceLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.8);
}

.price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
}
