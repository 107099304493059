.grid {
  display: grid;
  gap: 10px;
}
.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: rgba(0, 0, 0, 0.8);
}

.seeMoreButton {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-decoration-line: underline;

  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
}

.metricContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  border: 1px solid rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  color: #222;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}

.metricCtaContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.metricCta {
  padding: 0;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-weight: 800;
  color: #000;
  font-size: 16px;
  cursor: pointer;
}
