.profile-header {
  padding: 20px;
  background-color: #f0f2f5;
  border-radius: 8px;
}

.profile-header-row {
  margin-bottom: 20px;
}

.profile-name {
  margin-bottom: 0;
}

.profile-link {
  color: inherit;
  text-decoration: none;
}

.profile-username {
  display: flex;
  align-items: center;
  gap: 5px;
}

.profile-bio {
  margin-top: 10px;
}

.profile-details {
  margin-top: 40px;
}

.row-item {
  margin-bottom: 20px;
}

.row-item-label {
  display: block;
}

.row-item-desc {
  margin-top: 5px;
  font-weight: bold;
}
