.title {
  display: flex;
  align-items: center;
  gap: 16px;
}

.taglineContainer {
  display: flex;
  align-items: center;
  gap: 6px;
}

.name {
  color: rgba(0, 0, 0, 0.8);
  font-size: 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-block: 0;
}

.tagline {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block: 0;
}
