.tileNumber {
  background: #f5f5f5;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}
.tileHeader {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.tileHeader:hover {
  opacity: 0.7;
}

.subTileHeading {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  position: relative;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
}

.subTileHeading::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 999px;
  left: -25px;
  top: 6px;
  border: 2px solid #2130ff;
}

.image {
  width: 200px;
  /* height: 384px; */
  display: flex;
  flex-direction: column;
}

.draftImage {
  object-fit: cover;
  width: 200px;
  height: 384px;
}

.subTileHeadingClickable {
  cursor: pointer;
}

.subTileHeadingClickable:hover {
  opacity: 0.8;
}
