.container {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.title {
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 35px;
}

.anchor {
  color: #533af5;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.followerGrowth {
  /* color: #398200; */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.onlineFollowersGrowth {
  color: rgba(0, 0, 0, 0.7);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.green {
  font-weight: 600;
  color: #398200;
}

.graphContainer {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: grid;
  gap: 20px;
  position: relative;
}

.graphLabel {
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.optionsContainer {
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.metric {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.metricLabel {
  color: #00000080;
  text-align: right;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.secondaryDescription {
  color: #7f7f7f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
