.heading {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}

.item {
  display: grid;
  gap: 4px;
  margin-bottom: 16px;
}

.draftReq {
  display: flex;
  gap: 7px;
  margin-bottom: 16px;
  align-items: center;
}

.draftReq > p {
  font-weight: 500;
  font-size: 16px;

  color: rgba(0, 0, 0, 0.85);
}

.radioWrapper {
  align-items: start !important;
}

.radioWrapper > span {
  top: 1.2em !important;
}
