.download__card {
	display: flex;
	padding: 12px 16px;
	margin-top: 1rem;
	width: 100%;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.06);
	box-shadow: 0px 1px 9px rgba(33, 48, 255, 0.09);
	border-radius: 5px;
	align-items: center;
	flex-wrap: wrap;
}

.download__card div {
	margin-left: 1rem;
}
