.detailsCard > * {
  font-family: Inter;
  font-style: normal;
  line-height: normal;
}

.detailsCard h1 {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 20px;
}

.detailsCard h2 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: 600;
  margin-block: 20px;
}

.detailsCard h6 {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 0;
}
