.container {
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 0px 8px rgba(10, 6, 18, 0.04);
  border-radius: 8px;
  padding: 12px 16px;
  cursor: pointer;
}

.cName {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.date {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
}

.disabled {
  cursor: not-allowed;
}
