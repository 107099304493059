.metricTitle {
  color: #222;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.metricOcc {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.metricOcc span {
  color: #808080;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.metricSlider {
  width: 100%;
}

.metricSlider::-webkit-meter-optimum-value {
  background-color: #533af5;
}

.metricDesc {
  color: #333;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
