.home {
  background: #f9f9f9;
}

.container {
  display: flex;
  align-items: stretch;
  min-height: calc(100vh - 64px);
  justify-content: space-between;
}

.child1 {
  width: 40%;
  background-color: #f2f1f9;
  position: relative;
  /* border-radius: 0 500px 500px 0; */
}

.oval {
  height: 100%;
  width: 43%;
  /* background-color: #f2f1f9; */
  background-color: #f2f1f9;
  position: absolute;
  right: -43%;
  /* top: -100px; */
  z-index: 100;
  border-radius: 0 1100px 1000px 0;
}

.childContainer {
  border-radius: 1064px;
  width: 704px;
  height: 100%;
  background-color: #f2f1f9;
}

.blocktitle {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blockdesc {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.block {
  display: flex;
  align-items: center;
  gap: 14px;
}

.content,
.block {
  padding: 50px;
}

.pro {
  color: #533af5;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.gutter {
  margin-top: 50px;
}

.credits {
  color: #533af5;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}

.creditDesc {
  color: #222;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px;
}

.misc {
  color: #222;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 24px;
}

.misc2 {
  color: #222;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.child2 {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.child2Container {
  width: 420px;
}

.title {
  color: #000;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.desc {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
