.main {
  border-radius: 16px;
  padding: 100px 40px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  transition: background 1200ms linear;
  margin-block: 20px 40px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textSection {
  width: 420px;
  padding: 20px;
}

.textSection > * {
  color: #ffffff;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
}

.textSection h1 {
  font-size: 36px;
  margin-block: 0;
  margin-bottom: 20px;
}

.textSection h2 {
  font-size: 20px;
  margin-block: 0;
  margin-bottom: 20px;
}

.textSection p {
  font-size: 14px;
  font-weight: 400;
  margin-block: 0;
  margin-bottom: 20px;
}

.videoSection {
  width: 576px;
}
