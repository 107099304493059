.progressContainer {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 1px 9px rgba(33, 48, 255, 0.09);
  border-radius: 8px;
  padding: 12px 16px;
  width: fit-content;
  align-self: center;
}

.progressTitle {
  font-weight: 800;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.85);
}
.progressDesc {
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
}

.nTileContainer {
  display: flex;
  gap: 6px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 1px 9px rgba(33, 48, 255, 0.09);
  border-radius: 5px;
  align-items: center;
  padding: 14px 31px;
}
.nTileContainer > div {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.nTileLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
}

.modal {
}
