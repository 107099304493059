.heading {
  color: #222;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.subHeading {
  color: #222;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text {
  color: #222;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
}

.link {
  color: rgba(0, 0, 0, 0.85);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.buttonContainer {
  margin-top: 42px;
}

.formLabel {
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter";
  line-height: normal;
  color: "#000";
}
