.main {
  overflow: auto;
  min-height: 100vh;
}

.innerContainer {
  margin: 20px 100px;
}

.main * {
  box-sizing: border-box;
}

.header,
.steps {
  max-width: 100vw;
  width: 100%;
  /* padding: 0 64px; */
  margin: 2.5rem 0 3.75rem;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.header__h1 {
  font-size: 1.375rem;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 50px;
}

.header__p {
  font-size: 16px;
  font-weight: 400;
  color: #0f0f0f;
  opacity: 0.7;
  line-height: 20px;
}

.senderCard {
  /* padding: 0 64px; */
}

.senderCard__yellow {
  border-radius: 8px;
  width: 446px;
  padding: 20px;
}

.senderCard__blue {
  border-radius: 8px;
  width: 500px;
  padding: 20px;
  color: rgba(0, 0, 0, 0.7);
}

.bgBlue {
  background: rgba(83, 58, 245, 0.1);
}

.bgYellow {
  background: #f9ab0066;
}

.senderCard__text_multiple {
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter";
}

.senderCard__text_single {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
}

.senderCard__btn {
  text-decoration: underline;
  font-weight: 600;
}

.text_black {
  color: #000;
}

.text_blue {
  color: #533af5;
}

.card {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.card__container {
  /* padding: 0 20px; */
}

.card__header {
  font-size: 20px;
  font-weight: 600;
}

.card__header__border {
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  max-width: max-content;
}

.card__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #0f0f0f;
}

.card__text span {
  color: #000000;
  font-weight: 500;
}

.card__emailPreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 12px 24px;
  gap: 12px;
  background: #f5f7fa;
  border-radius: 10px;
  margin-top: 16px;
}

.card__emailPreview p {
  font-size: 18px;
  font-weight: 400;
}

.card__emailPreview span {
  color: #176fff;
}

.card__footer {
  height: 64px;
  /* border-top: 1px solid rgba(0, 0, 0, 0.2); */
  padding: 60px 0 30px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.footer__icon {
  width: 24px;
  height: 24px;
}

.modal__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__text {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  margin-block: 12px;
  text-align: center;
}

.emailPreview {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 50px;
}

.childContainer {
  padding: 20px;
}

.formLabel {
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter";
  line-height: normal;
  color: "#000";
}

.phoneLabel {
  margin-bottom: 10px;
}

.optionsContainer {
  display: flex;
  padding: 14px 10px;
  max-width: 150px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  background-color: #fafafa;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;
}

.optionsContainer:hover {
  border-color: #176fff;
}

.singlePreviewCard {
  border-radius: 8px;
  width: 320px;
  padding: 20px;
  color: rgba(0, 0, 0, 0.7);
  background: rgba(83, 58, 245, 0.1);
}
