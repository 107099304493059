.heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 12px;
}

.container {
  background: #ffffff;
  height: 91.7vh;
}
