.container {
  padding: 12px 24px;
  border: 1px solid rgba(0, 0, 0, 0.45);
  border-radius: 5px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
}

.selected {
  color: #533af5;
  border: 2px solid #533af5;
}

.disabled {
  cursor: not-allowed;
  color: #00000040;
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
