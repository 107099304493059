.menuContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  /* padding-bottom: 10px;  */
  /* Adjust as needed to match your design */
}

.main {
  overflow: auto;
  min-height: 100vh;
}

.innerContainer {
  margin: 20px 100px;
}

.childContainer {
  padding: 20px;
}

.heading {
  color: #222;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.uploadButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 33px 28px;
  gap: 4px;
  background: #ffffff;
  border: 2px dashed rgba(56, 78, 183, 0.3);
  border-radius: 4px;
  width: 344px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #676767;
}

.errorInput {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: red;
}

.titleLabel {
  display: inline-flex;
  gap: 6px;
  align-items: center;
}

.profile {
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.name {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-weight: 500;
}
