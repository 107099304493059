.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  color: #c7c7c7;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}

.selected {
  border: 1px solid #533af5;
  color: #533af5;
}
