.container {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: grid;
  gap: 20px;
}

.title {
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.innerContainer {
  padding: 20px;
  display: grid;
  gap: 20px;
}

.reviewNumber {
  color: rgba(0, 0, 0, 0.7);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.reviewCount {
  color: rgba(0, 0, 0, 0.9);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
