.staticCard {
  border-radius: 8px;
  background: rgba(249, 171, 0, 0.4);
  padding: 20px;
}

.label {
  color: #000;
  font-size: 22px;
  font-family: Inter;
  font-weight: 600;
}

.desc {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
}

.staticDesc {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.grid40 {
  display: grid;
  padding: 20px;
  gap: 40px;
}
.grid20 {
  display: grid;
  gap: 20px;
}

.labelInput {
  color: #000;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
}
.subLabelInput {
  color: #000;
  font-size: 12px;
  font-family: Inter;
  font-weight: 300;
  line-height: 15px;
}

.p20G10 {
  display: grid;
  gap: 10px;
  padding: 20px 0;
}

.removeDescLight {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.removeHeading {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.removeHeadingLabel {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
