.container {
  padding: 10px 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
}

.profile {
  height: 50px;
  width: 50px;
  border-radius: 999px;
}

.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.username {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.selectedItem {
  border: 2px solid #533af5;
  border-radius: 8px;
}
.followers {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}

.tag {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #8a3329;
}
