.profile {
  height: 50px;
  width: 50px;
  border-radius: 999px;
}

.name {
  display: -webkit-box;
  overflow: hidden;
  max-height: 60px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  text-overflow: ellipsis;
  word-break: break-word;
}

.username {
  color: #424242;
  font-size: 14px;
}

.followers {
  font-size: 20px;
  line-height: 1.25;
  color: #333;
}

.label {
  color: #9e9e9e;
  font-size: 14px;
  line-height: 1.25;
}
