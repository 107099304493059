.container {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 40px 44px;
  padding-bottom: 0;
}

.title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  width: max-content;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
}

.uploadButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 33px 28px;
  gap: 4px;
  background: #ffffff;
  border: 2px dashed rgba(56, 78, 183, 0.3);
  border-radius: 4px;
  width: 344px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #676767;
}

.lsdsdsd {
  /* width: 344px !important; */
  /* height: 149px !important; */
}

.bannerHeading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #0f0f0f;
  margin-bottom: 8px;
}

.subHeading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #0f0f0f;
}

.error {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: red;
}
