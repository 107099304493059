.heading {
  margin-bottom: 21px;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.85);
}

.container {
  display: grid;
  gap: 16px;
  padding: 20px;
}

.rounded {
  border-radius: 999px;
}
.rounded__12 {
  border-radius: 12px;
}

.noKycHeading {
  font-weight: 500;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
}

.noKycDesc {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}

.outerContainer {
  /* padding: 20px; */
  /* border-radius: 10px; */
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 0 8px 8px 0;
}

.inputLabel {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

.inputValue {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 2px;
}

.tileHeading {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.tileDesc {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
}

.tile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tileBlock {
  display: grid;
  gap: 2px;
}

.actionBlock {
  display: flex;
  gap: 20px;
  align-items: center;
}

.innerGrid {
  display: grid;
  gap: 12px;
}

.header {
  padding: 20px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(55, 53, 47);
}
