.modalBody {
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.modalContent {
  flex: 1;
  display: grid;
  place-items: center;
}

.modalContentContainer {
  position: relative;
}

.modalContentBG {
  background-color: white;
  position: relative;
  z-index: 10;
  max-width: max-content;
}

.modalArrow {
  position: absolute;
  width: 460px;
  transform: rotate(-9deg);
  left: 15%;
  top: -35px;
  z-index: 10;
}

.modalContent h2 {
  font-weight: 600;
  font-size: 42px;
  line-height: 58px;
  margin-bottom: 0;
}

.modalContent p {
  font-weight: 400;
  font-size: 18px;
  color: #533af5;
}

.modalContent h3 {
  font-weight: 500;
  font-size: 20px;
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.modalContent h3 img {
  position: absolute;
  left: -8%;
}

.modalCalender {
  flex: 1;
  position: relative;
}
