.gridContainer {
  display: grid;
  gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #7f7f7f;
}

.titleGrey {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.5);
}

.learnMore {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-decoration: underline;
  color: #7f7f7f;
  cursor: pointer;
}

.check::-webkit-scrollbar {
  height: 0px;
}
