.container{
    margin:0 auto;
    padding:80px 0 ;
    background: #F9F9F9;
    min-height: 100vh;
}

.heading{
    font-weight: 800;
    font-size: 38px;
    line-height: 46px;
    text-align: center;
}

.label{
    font-weight: 500;
    font-size: 20px !important;
    line-height: 28px !important;
    text-align: left;
    margin-bottom:24px !important ;
}

.passwordLabel{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}

.input{
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    padding: 8px 13px;
}

.submitButton{
    /* background: linear-gradient(93.85deg, hsl(239, 100%, 54%) -5.36%, #7D54E6 103.55%) !important; */
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043)!important;
    border-radius: 5px!important;
    width: 100%;
    outline: none;
    padding: 14px 0 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 28px !important;
    cursor: pointer;
    text-align: center;
    /* border: none !important; */
    /* color: #FFFFFF !important; */
    height: 60px !important;

}
/* .submitButton:hover{
   background: #40a9ff; 
} */


.highlight{
    text-decoration: underline;
    text-decoration-color: #3476EC;
    color: #3476EC;
}

.modalTitle{
    font-weight: 800;
    font-size: 38px;
    line-height: 46px;
    color: #000000;
}

.modalDesc{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
}

.modalButton{
    background: #FFFFFF;
    border-radius: 5px !important;
    padding: 0 110px !important;
    align-items: center !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 28px !important;
    color: rgba(0, 0, 0, 0.85) !important;
}

.footer{
    text-align: center;
    padding: 0px 58px;
}

.resend{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    cursor: pointer;

    color: #080BF4;
}


.disabled{
    color:#595959;
    cursor: not-allowed;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

}

.tooltip{
    font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #595959;
}

.inputLabel{
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;    
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 10px;
}

.logo{
    border-radius: 50%;
    width: 150px;
    height: 150px;
}
.close{
    position: absolute;
    right: 0px;
    cursor: pointer;
}

.submitButtonText{
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    cursor: pointer;
    text-align: center;
    border: none;
    color: #FFFFFF;
}


.hint{

font-weight: 400;
font-size: 14px;
line-height: 22px;

color: rgba(0, 0, 0, 0.45);
}

.radioTitle{
font-weight: 500;
font-size: 20px;
line-height: 28px;
margin-bottom: 8px !important;
color: rgba(0, 0, 0, 0.85);
}

.radioDesc{
    font-weight: 400 !important; 
    font-size: 16px !important;
    line-height: 24px !important;
    margin-top: 0 !important;
    color: #595959 !important;
    
}

.radioHeading{
    color: #595959;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}


.submitButtonSecondary{
    filter: drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.043))!important;
border-radius: 5px !important;

padding: 14px!important;
height: min-content!important;
background: linear-gradient(93.85deg, #1619FF -5.36%, #7D54E6 103.55%) !important;
-webkit-background-clip: text !important;
-webkit-text-fill-color: transparent !important;
background-clip: text !important;
text-fill-color: transparent !important;
font-weight: 500 !important;
font-size: 20px !important;
line-height: 28px !important;

}
.googleButton{
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 28px !important;
    padding: 14px!important;
    height: min-content!important;
    text-align: center;
color: rgba(0, 0, 0, 0.85);
}

.submitButtonSecondary :hover{
    background: linear-gradient(93.85deg, #1619FF -5.36%, #7D54E6 103.55%) !important;
-webkit-background-clip: text !important;
-webkit-text-fill-color: transparent !important;
background-clip: text !important;
text-fill-color: transparent !important;
}

.helpTool{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #B7B7B7;
}