.error {
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #8a3329;
  margin-top: 20px;
}

.g20 {
  display: grid;
  gap: 20px;
}

.primaryText {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.link {
  color: #533af5;
  text-decoration: underline;
}
