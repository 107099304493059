.container {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile {
  height: 40px;
  width: 40px;
  border-radius: 999px;
}

.name {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.username {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.5);
}

.selectedItem {
  border: 2px solid #1106f7;
  filter: drop-shadow(0px 0px 4px rgba(83, 58, 245, 0.5));
}
