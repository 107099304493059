.subLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.45);
}

.input {
  background-color: transparent !important;
  width: 40%;
}

.label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
  color: #000000;
}

.error {
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #8a3329;
  margin-top: 0px;
}

.link {
  color: #533af5;
  text-decoration: underline;
}
