.header {
  background: #f5f5f5;
  box-shadow: inset 1px 0px 0px #d9d9d9, inset -1px 0px 0px #d9d9d9,
    inset 0px 1px 0px #d9d9d9, inset 0px -1px 0px #d9d9d9;
  border-radius: 10px;

  padding: 16px 10px 16px 44px;
}

.headerItem {
  /* text-align: center; */
  letter-spacing: 0.065em;

  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  /* font-family: 'Roboto'; */
}
