.success {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 66px;
}

.title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 49px;
  color: #000000;
  margin: 2px !important;
}

.desc {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #0f0f0f;
  opacity: 0.5;
}

.help {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #0f0f0f;
}

.demo {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #533af5;
  cursor: pointer;
}
