.container{
    display: flex;
    gap:12px;
}

.profileImg{
 width:41px;
 height: 41px;
 border-radius: 100px;   
}

.pName{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px; 
}

.bName{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}

.review{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}
