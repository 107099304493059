.followersRoot {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.followersSubmit {
  margin: 15px auto;
}

.inputContainer {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.filterHeading {
  font-size: 20px;
}

.filterDesc {
  font-size: 14px;
  line-height: 21px;
}
