.container {
  padding: 13px 15px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}

.profileInfo {
  display: flex;
  gap: 10px;
  align-content: center;
}

.profileImage {
  border-radius: 100px;
  border: 1px solid #c4c4c4;
  width: 57px;
  flex-shrink: 0;
  height: 57px;
}

.cName {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.followers {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}

/* .divider{
    border: 1px solid rgba(0, 0, 0, 0.06);
    width: 1000%;
} */
