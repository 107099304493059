.container__login {
  background: #f9f9f9;
  padding: 40px;
  min-height: 100vh;
  max-width: 1536px;
  margin: 0 auto;
}

.main {
  height: 100%;
  max-width: 1440px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}

.main * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container__verify {
  padding: 80px;
  background: #f9f9f9;
  min-height: 100vh;
}

.container {
  display: flex;
  align-items: stretch;
  height: 100%;
  overflow: hidden;
}

.container .block {
  width: 100%;
}

.heading {
  font-weight: 800;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
}

.label {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  margin-bottom: 6px !important ;
  font-family: "Manrope";
}

.passwordLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  font-family: "Manrope";
}

.input {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 24px !important;
  border-radius: 5px !important;
  font-family: "Manrope";
}

.submitButton {
  background: linear-gradient(180deg, #7d52e3 -26.67%, #1106f7 189.17%);

  font-family: "Manrope";
  border-radius: 5px !important;
  width: 100%;
  outline: none;
  padding: 12px 42px !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  cursor: pointer;
  text-align: left !important;
  display: flex !important;
  justify-content: center !important;
  gap: 10px;
  align-items: center;

  height: min-content !important;
}
/* .submitButton:hover{
   background: #40a9ff; 
} */

.highlight {
  text-decoration: underline;
  text-decoration-color: #3476ec;
  color: #3476ec;
}

.resend {
  font-weight: 600;
  font-size: 16px;
  /* line-height: 26px; */
  cursor: pointer;
  color: #0000ff;
  font-family: "Manrope";
  margin-left: 8px;
}

.disabled {
  color: #595959;
  cursor: not-allowed;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.tooltip {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
}

.inputLabel {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 10px;
  font-family: "Manrope";
}

.logo {
  border-radius: 50%;
  width: 150px;
  height: 150px;
}
.close {
  position: absolute;
  right: 0px;
  cursor: pointer;
}

.submitButtonText {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  cursor: pointer;
  text-align: center;
  border: none;
  color: #ffffff;
}

.hint {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: "Manrope";
  color: rgba(0, 0, 0, 0.45);
}

.radioTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px !important;
  color: rgba(0, 0, 0, 0.85);
}

.radioDesc {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin-top: 0 !important;
  color: #595959 !important;
}

.radioHeading {
  color: #595959;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.submitButtonSecondary {
  filter: drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.043)) !important;
  border-radius: 5px !important;

  padding: 14px !important;
  height: min-content !important;
  background: linear-gradient(
    93.85deg,
    #1619ff -5.36%,
    #7d54e6 103.55%
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-fill-color: transparent !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 28px !important;
}
.googleButton {
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  padding: 12px 42px !important;
  height: min-content !important;
  display: flex !important;
  justify-content: center !important;
  gap: 10px;
  color: rgba(0, 0, 0, 0.85);
  font-family: "Manrope";
}

.submitButtonSecondary :hover {
  background: linear-gradient(
    93.85deg,
    #1619ff -5.36%,
    #7d54e6 103.55%
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-fill-color: transparent !important;
}

.helpTool {
  font-family: "Manrope";
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

.sidebox {
  background-color: rgba(8, 11, 244, 0.8);
  border-radius: 0 60px;
  padding: 42px 50px 18px;
  width: 100%;
  background-image: url("/public/assets/icons/cropped-site-icon-cloutflow\ 1.svg");
  background-position: center;
  display: grid;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 512px;
  max-height: 854px;
  height: 100%;
  overflow: auto;
  transition: all 0.25s;
}

.sidebox::-webkit-scrollbar {
  width: 0px;
}

.sidebox__heading {
  font-size: 24px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 28px;
}

.sidebox__text {
  text-transform: capitalize;
  font-size: 16px;
  color: #ffffff;
}

.sidebox__feature {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  text-transform: capitalize;
  color: #ffffff;
  margin: 10px 0;
}

.sidebox__feature span {
  opacity: 60%;
}

.sidebox__marquee {
  margin-top: 12px;
}

.sidebox__brand {
  margin-inline: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 120px; */
}

.container .block:nth-child(1) {
  position: relative;
  padding: 12vh 50px;
}

.logo {
  position: absolute;
  top: 4vh;
  left: min(5vw, 36px);
}

.container .block:nth-child(2) {
  background-color: #f5f5f5;
  padding: 50px;
}

@media screen and (max-width: 1440px) {
  .main {
    max-width: 100vw;
  }
}

.otpLabel {
  text-align: left;
}
