.modalClose {
  color: rgb(189, 0, 42);
  font-weight: 400;
  font-size: 16px;
}

.modalTitle {
  font-weight: 500;
  font-size: 20px;
}

.modalFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.addressLabel {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  color: #0f0f0f;
}
