.grid {
  display: grid;
  gap: 25px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.info {
  color: #7f7f7f;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.instaContainer {
  display: flex;
  gap: 2px;
  margin-top: 10px;
  align-items: center;
}
.instaButton {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  text-decoration-line: underline;
}

.reachContainer {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}

.optionsContainer {
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.reach {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
