/* .searchContainer {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  border-radius: 10px;
  width: 100%;
  padding: 10px;
} */

.form {
  /* border: none !important; */
  border-color: transparent !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.form:active {
  /* border: none !important; */
  border-color: transparent !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.inputContainer {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 10px;

  height: 70px;
  justify-content: space-between;
}
