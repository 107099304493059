.box {
  max-width: 720px;
  margin-inline: auto;
  text-align: center;
  margin-block: 80px;
}

.heading {
  font-size: 36px;
  font-weight: 500;
  margin: 0;
}

.text {
  font-weight: 400;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 36px;
  color: rgb(15, 15, 15);
}

.addressLabel {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  color: #0f0f0f;
}
