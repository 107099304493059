.title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: space-between;
}

.ageList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.followersRoot {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.followersSubmit {
  margin: 15px auto;
}

.download__card {
  padding: 12px 8px;
  margin: 0.5rem;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 1px 9px rgba(33, 48, 255, 0.09);
  border-radius: 5px;
  text-align: center;
  margin-bottom: 2rem;
}
