.messageContainerMe {
  display: flex;
  gap: 10px;
  max-width: 100%;
}
.messageContainerOther {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  max-width: 100%;
}

.profile {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  object-fit: contain;
  border: 1px solid #dddddd;
  object-fit: cover;
}

.cName {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.time {
  font-size: 10px;
  /* color: rgba(0, 0, 0, 0.45); */
}
.timeWhite {
  font-size: 10px;
  color: white;
}

.messageOther {
  min-height: 38px;
  min-width: 100px;
  max-width: 100%;
  overflow-wrap: break-word;
  /* border: 1px solid rgba(13, 114, 234, 0.16); */
  /* background-color: #ebf2fe; */
  background-color: #f3f3f5;
  border-radius: 0 10px 10px 10px;
  /* display: flex; */
  align-items: center;
  padding: 6px 12px;
  white-space: pre-wrap;
  position: relative;

  padding-bottom: 20px;
}
.messageMe {
  min-height: 38px;
  min-width: 100px;
  max-width: 100%;
  overflow-wrap: break-word;
  /* border: 1px solid rgba(0, 0, 0, 0.08); */
  /* background-color: #ffffff; */
  background-color: #097bfd;
  border-radius: 10px 0 10px 10px;
  /* display: flex; */
  align-items: center;
  padding: 6px 12px;
  white-space: pre-wrap;
  position: relative;
  padding-bottom: 20px;
  color: white;
  display: grid;
}

.iconPosition {
  position: absolute;
  top: 1px;
  right: 1px;
  cursor: pointer;
}

.bottomRight {
  position: absolute;
  bottom: 2px;
  left: 12px;
  display: inline-flex;
  align-items: center;
  z-index: 10;
}

.bottomLeft {
  position: absolute;
  right: 12px;
  bottom: 2px;
  z-index: 10;
  display: inline-flex;
  align-items: center;
}

.dateContainer {
  padding: 5px 12px 6px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
  background-color: hsla(0, 0%, 100%, 0.95);
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
  color: rgb(26, 0, 0);
  width: fit-content;
  margin: 10px auto;
}

.deletedMessage {
  background-color: rgb(215, 208, 208);
  font-style: italic;
  color: white;
}
