.container {
  border-radius: 18px;
  background-color: rgb(239, 239, 239);
  width: fit-content;
}

.innerContainer {
  padding: 12px;
}

.title {
  color: rgb(0, 0, 0);
  font-weight: 600;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  white-space: pre-line;
  overflow-x: hidden;
  overflow-y: hidden;
  word-wrap: break-word;
  white-space: pre-line;
  font-size: 12px;
}

.subTitle {
  color: rgb(115, 115, 115);
  font-weight: 400;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  white-space: pre-line;
  overflow-x: hidden;
  overflow-y: hidden;
  word-wrap: break-word;
  white-space: pre-line;
  font-size: 12px;
  margin-top: 8px;
}

.button {
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(219, 219, 219) !important;
  border-radius: 8px !important;
  color: rgb(38, 38, 38) !important;
  font-weight: 600 !important;
  text-overflow: ellipsis;
}

.buttonsGrid {
  display: grid;
  margin-top: 12px;
  gap: 8px;
}

.image {
  height: 200px;
  aspect-ratio: auto 382 / 200;
  width: 382px;
  object-fit: cover;
}
