.title {
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  margin-bottom: 0px !important;
}

.title_container {
  padding: 20px 64px;
  margin-top: 20px;
}

.campaignHeader {
  background: #f5f5f5;
  box-shadow: inset 1px 0px 0px #d9d9d9, inset -1px 0px 0px #d9d9d9,
    inset 0px 1px 0px #d9d9d9, inset 0px -1px 0px #d9d9d9;
  border-radius: 10px;

  padding: 16px 10px 16px 44px;
  display: grid;
  grid-template-columns: 24.5% 24.5% 24.5% 24.5% 2.5%;
}

.headerItem {
  /* text-align: center; */
  letter-spacing: 0.065em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  /* font-family: 'Roboto'; */
}

.tileContainer {
  padding: 16px 10px 16px 44px;
  text-align: center;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  display: grid;
  grid-template-columns: 24.5% 24.5% 24.5% 24.5% 2.5%;
}

.cName {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  width: 155px;
  text-align: left;
}

.cDate {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #595959;
}

.tag {
  padding: 4px 0 !important;
  border-radius: 60px !important;
  width: 160px;
}

.buttonContainer {
  padding: 0 64px;
  margin-block: 20px;
}

.buttonTitle {
  color: rgba(0, 0, 0, 0.8);
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
}

.buttonText {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blueTitle {
  color: #533af5;
}

.rounded_selected {
  border-radius: 10px;
  background-color: #533af50d;
  padding: 20px 15px;
}

.rounded {
  border-radius: 10px;
  background-color: #533af50d;
  border-color: transparent;
  padding: 20px 15px;
}
