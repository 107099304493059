.panelHeading {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: black;
}
.profileName {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  margin-top: 16px;
}
.profileData {
  font-weight: 400;
  font-size: 14px;
  line-height: 108%;
  color: rgba(0, 0, 0, 0.45);
}

.reviewNumber {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

.reviewCount {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}

.nestedPanelHeading {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.creatorProfile {
  width: 91px;
  height: 91px;
  border-radius: 100px;
  object-fit: fill;
  position: relative;
}

.editIcon {
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 10;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
