.closeButton {
  font-family: "Manrope" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #bd002a !important;
}

.scrollableContainer {
  height: 600px;
  overflow-y: scroll;
  padding-right: 50px;
}

.heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #1a1a1a;
  margin-bottom: 30px;
}

.uploadButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 33px 28px;
  gap: 2px;

  width: 197px;
  height: 110px;

  background: #f8f8ff;
  border: 2px dashed rgba(56, 78, 183, 0.3);
  border-radius: 4px;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #676767;
}
