.container {
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25)); */
  border-radius: 8px;
  padding: 20px;
}

.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 6px;
}

.username {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.metricLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #7f7f7f;
}

.metric {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #222222;
}

.image {
  border-radius: 999px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.carouselImage {
  border-radius: 8px;
  width: 146px;
  height: 200px;
}

.imageContainer {
  position: relative;
  width: 146px;
  height: 230px;
}

.optionsContainer {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-decoration-line: underline;

  color: #533af5;
}

.check::-webkit-scrollbar {
  height: 9px !important;
}
