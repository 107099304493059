.container {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  gap: 20px;
  padding: 20px;
  display: grid;
}

.title {
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.desc {
  color: #7f7f7f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.graphContainer {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: grid;
  gap: 20px;
}

.graphLabel {
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
