.name {
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profilePic {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}

.profilePicContainer {
  position: relative;
}

.platformContainer {
  position: absolute;
  bottom: 4px;
  right: -4px;
  padding: 4px;
  border-radius: 18px;
  border: 0.5px solid rgba(83, 58, 245, 0.1);
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(83, 58, 245, 0.1);
  display: flex;
  flex-shrink: 0;
}

.container {
  padding: 40px 150px;
}

.InfoContainer {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  border-radius: 8px;
}

.instaBg {
  background: rgba(235, 63, 121, 0.1);
}

.ytBg {
  background: rgba(255, 25, 0, 0.1);
}

.noAccessContainer {
  background: rgba(83, 58, 245, 0.1);
}

.instaTitle {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.instaDesc {
  color: #7f7f7f;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.learnMore {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-decoration: underline;
  color: #7f7f7f;
  cursor: pointer;
}

.innerContainer {
  padding: 20px 25px;
}
