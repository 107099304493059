.container{
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: 0px 1px 9px rgba(33, 48, 255, 0.09);
    border-radius: 5px;
}
.space{
display: flex;
justify-content: space-between;
}
.highlight{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.light{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #9D9D9D;
}

.lightThin{
    font-weight: 400;
font-size: 12px;
line-height: 20px;
}
.boldLight{
font-weight: 500;
font-size: 14px;
line-height: 22px;
}

