.card {
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 1px 9px rgba(33, 48, 255, 0.09);
  border-radius: 5px;
  margin-top: 16px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}

.desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
}
