.titleContainer {
  margin-bottom: 20px;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  font-size: 18px;
}

.textArea {
  position: relative;
}

.message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
