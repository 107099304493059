.container{
    background: #F0EFF8;
    border-radius: 23px;
    padding: 2px 8px;
    display: flex;
    justify-items: center;
    align-items: center;
    gap:8px;
}

.chipText{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #4065D4;
}