.container {
  display: flex !important;
  padding: 10px 20px;
  height: fit-content !important;
  border-radius: 8px !important;
  align-items: center;
  box-shadow: none;
}

.container:hover {
  opacity: 0.8 !important;
}

.primary {
  border-color: #533af5 !important;
  background: #533af5 !important;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important; */
}

.default {
  color: #533af5 !important;
  border: 1px solid #533af5 !important;
}

.ghost {
  color: #533af5 !important;
  border-color: transparent !important;
}

.outline {
  border: 1px solid #533af5 !important;
  color: #533af5 !important;
}
.outlineDanger {
  border: 1px solid #ef4351 !important;
  color: #ef4351 !important;
}

.disabled {
  color: #00000040 !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
}
