.container {
  border: 1px solid #d9d9d9;
  width: 100%;
  max-width: 460px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  border-radius: 2px;
}
.container::-webkit-scrollbar {
  display: none;
}

.container:hover {
  border: 1px solid #303bff;
}

.blockType {
  font-size: 10px;
  /* border: none; */
  color: rgba(0, 0, 0, 0.85);
}
.blockType:hover {
  box-shadow: none;
}
.blockTypeDropdown {
  font-size: 10px;
  /* border: none; */
  color: rgba(0, 0, 0, 0.85);
}
.blockType:hover {
  font-size: 10px;
}

.textArea {
  padding: 10px;
  min-height: 150px;
}
