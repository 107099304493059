.container {
  display: flex;
  height: 10vh;
  padding: 12px 18px 24px;
  gap: 16px;
  /* border: 1.5px solid #000000; */
  background: #f0f2f5;
  align-items: center;
  border-radius: 0 0 8px 8px;
}
