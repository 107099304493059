.item:hover {
  background: #e7e6fe !important ;
  border-radius: 8px !important ;
}

.dropDownContainer {
  background: #ffffff !important ;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25) !important ;
  border-radius: 8px !important ;
  padding: 10px !important ;
}
