.heading {
  font-weight: 600;
  font-size: 28px;
  color: rgb(55, 53, 47);
  line-height: 1.3;
  /* text-align: center; */
}

.subHeading {
  font-weight: 400;
  font-size: 18px;
  color: rgba(55, 53, 47, 0.65);
  line-height: 1.3;
  padding-top: 2px;
  margin-bottom: 60px;
  /* text-align: center; */
}

.uploadButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 33px 28px;
  gap: 2px;

  width: 120px;
  height: 120px;

  background: #f8f8ff;
  border: 2px dashed rgba(56, 78, 183, 0.3);
  border-radius: 100px;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #676767;
}
