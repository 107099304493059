.blocktitle {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blockdesc {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 8px;
}

.block {
  display: flex;
  align-items: center;
  gap: 14px;
  text-align: center;
  justify-content: center;
  height: 180px;
}

.content {
  background-color: #f9f9f9;
  min-height: calc(100vh - 250px);
  padding-top: 50px;
}

.box {
  width: 868px;
  border-radius: 11px;
  border: 1px solid #d9d9d9;
  background: #fff;
  margin: auto;
}
