.container {
  width: 100%;
}

.filters {
  display: flex;
  padding: 33px 0px;
  gap: 8px;
}

.count {
  display: flex;
  justify-content: space-between;
  padding: 14px 0px 8px;
  gap: 12px;
  margin-bottom: 12px;
}

.count:nth-child(2) {
  display: flex;
  align-items: flex-end;
}

.count > div > p:nth-child(1) {
  font-size: 16px;
  font-weight: 600;
}
.count > div > p:nth-child(2) {
  font-size: 12px;
}

.table {
}

.tableHeader {
  padding: 12px 16px;
  background-color: #fafafa;
  display: grid;
  border-radius: 4px;
  grid-template-columns: 10% 20% 15% 25% 15% 15%;
}

.tableHeader > p {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}

.tableBody {
  padding: 12px 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
}

.date {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  font-size: 16px;
  padding: 12px 8px 0px;
}

.noInvoiceHeading {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
}
.noInvoiceSubHeading {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 10px;
}
