@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.messageContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 16px;
  max-width: 100%;
}

.profile {
  width: 44px;
  height: 43px;
  border-radius: 100px;
}

.cName {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.time {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
}

.message {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  width: 400px;
  overflow-wrap: break-word;
}

.card {
  margin-top: 12px;
  max-width: 341px;
  width: 341px;
  font-family: "Roboto", sans-serif;
}

.cardInner {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e4e0e0;
  padding-bottom: 10px;
  font-size: 14px;
}

.cardButton {
  display: flex;
  justify-content: end;
}

.requirementCardInner {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e4e0e0;
  padding-bottom: 10px;
  gap: 8px;
  padding-left: 15px;
}
.requirementsHeading {
  font-weight: 600;
  font-size: 14px;
}

.deliverable > p {
  opacity: 45%;
}

.deliverable > div {
  margin-top: 10px;
  opacity: 45%;
  display: flex;
  flex-wrap: wrap;
  max-width: 250px;
  align-items: center;
  gap: 10px;
}

.deliverable > div > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.requirements > p {
  opacity: 45%;
  max-width: 300px;
}
.captions > p {
  opacity: 45%;
  max-width: 300px;
}

.references > div {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.references > div > img {
  width: 90px;
  height: 95px;
}

.lastDate {
  color: #2130ff;
  font-weight: 600;
  font-size: 14px;
}

.draftHeading {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 7px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.draft {
  margin-top: 16px;
  width: 239px;
  border: 1px solid #eeeeee;
  border-radius: 10px 10px 10px 10px;
}

.draft > h3 {
  margin: 0;
  padding: 4px 16px;
  border: 1px solid #eeeeee;
  border-radius: 8px 8px 0px 0px;
}

.video {
  width: 237.5px;
  height: 384px;
}
.image {
  width: 237.5px;
  /* height: 384px; */
  display: flex;
  flex-direction: column;
}

.draftImage {
  object-fit: cover;
  width: 237.5px;
  height: 384px;
}

.draftButton {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding-bottom: 13px;
}

.draftApproved {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 12px 16px;
}

.messageMe {
  /* border: 1px solid rgba(0, 0, 0, 0.08); */
  /* background-color: #ffffff; */
  background-color: #097bfd;
  border-radius: 10px 0 10px 10px;
  margin-top: 10px;
  padding: 4px;
  position: relative;
  padding-top: 15px;
}

.messageOther {
  /* border: 1px solid rgba(13, 114, 234, 0.16); */
  /* background-color: #ebf2fe; */
  background-color: #f3f3f5;
  border-radius: 0 10px 10px 10px;
  margin-top: 10px;
  padding: 4px;
  position: relative;
}

.overlay {
  position: absolute;
  height: 20%;
  border-radius: 0px 0px 8px 8px;
  bottom: 4px;

  width: 97%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.1) 50%
  );
}

.time {
  font-size: 10px;
  color: white;
}

.timeForDoc {
  font-size: 10px;
  /* color: rgba(0, 0, 0, 0.45); */
  /* color: white; */
}
.timeForDocWhite {
  font-size: 10px;
  /* color: rgba(0, 0, 0, 0.45); */
  color: white;
}

.bottomRight {
  position: absolute;
  bottom: 2px;
  left: 12px;
  display: inline-flex;
  align-items: center;
  z-index: 10;
}

.bottomLeft {
  position: absolute;
  right: 12px;
  bottom: 2px;
  z-index: 10;
  display: inline-flex;
  align-items: center;
}

.message {
  /* color: #65666f; */
  width: max-content;
}

.messageWhite {
  color: #ffffff;
  width: max-content;
}

.deletedMessage {
  background-color: rgb(215, 208, 208);
  font-style: italic;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 6px;
  min-height: 50px;
}

.iconPosition {
  position: absolute;
  top: 1px;
  right: 1px;
  cursor: pointer;
  color: white;
}
