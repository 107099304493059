.container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  width: 231px;
  overflow: hidden;
}

.label {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  overflow-wrap: break-word;
  width: 90%;
}

.desc {
  color: #7f7f7f;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.metric {
  color: #000;
  font-size: 22px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
