.extra {
  color: #2075f3;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}

.profile {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 999px;
}

.notification {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin: 16px 16px;
  padding: 10px;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  font-size: small;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  max-width: 280px;
}

.name {
  font-weight: 700;
}
.desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.extraOptions {
  display: flex;
  justify-content: space-between;
}

.extraOptions > span {
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 2px;
}

.extraOptions > p {
  font-weight: 600;
  cursor: pointer;
  font-size: 12px;

  color: rgba(0, 0, 0, 0.85);
}
