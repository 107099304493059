.tagContainer {
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  border-radius: 4px;
  padding: 0 6px;
  font-weight: 600;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  width: fit-content;
}

.success {
  color: #006908;
  background: #d7f732;
  border-color: #a6eb84;
}
.error {
  color: #b3093c;
  background: #ffe7f2;
  border-color: #ffccdf;
}
.processing {
  color: #a82c00;
  background: #fcedb9;
  border-color: #fcd579;
}

.heading {
  text-transform: uppercase;
  line-height: 20px;
  font-size: 14px;
  color: rgb(26, 31, 54);
}

.rowItem {
  line-height: 24px;
  font-size: 16px;
  color: #21252c;
}

.invoicesContainer {
  display: grid;
  gap: 24px;
}

.headingModal {
  color: #222;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
