.homeContainer {
  margin: 0 auto;
  padding-top: 20%;
  width: 50%;
  min-height: 100vh;
}

.homeHeading {
  font-weight: 800;
  font-size: 38px;
  line-height: 46px;
  color: #000000;
  text-align: center;
  margin-bottom: 8px;
}

.homeButton {
  margin-top: 49px;
  padding: 11px 45px;
  background: #9e9e9e;
  text-align: center;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.85);
  outline: none;
  border: none;
  cursor: pointer;
  color: #000000;
}
.homeDesc {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  max-width: 420px;
  margin: 0 auto;
}

.desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.heading {
  font-weight: 800;
  font-size: 38px;
  line-height: 46px;
  color: #000000;
}

.price {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;

  /* text-align: right; */

  color: rgba(0, 0, 0, 0.85);
}

.selectTag {
  background: #f0f5ff;
  cursor: pointer;
  border: 1px solid #adc6ff;
  border-radius: 39px;
  padding: 10px 24px;
}

.selectTagText {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #2f54eb;
}

.selectTagActive {
  padding: 10px 24px;
  background: #533af5;
  cursor: pointer;
  border-radius: 39px;
  border: 1px solid #533af5;
}

.selectTagTextActive {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.headingSecondary {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  line-height: 28px;
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.secondaryLabel {
  color: #000;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hint {
  color: rgba(0, 0, 0, 0.45);
}

.hintDescription {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
}

.grid {
  display: grid;
  gap: 54px;
  margin: 80px 0 0;
}

.hover:hover {
  opacity: 0.9;
}
