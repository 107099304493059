.profileContainer {
  display: flex;
  align-items: center;
  gap: 11px;
  margin-block: 15px;
}

.profilePic {
  width: 42px;
  object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 999px;
}

.bName {
  color: #222;
  font-size: 14px;
  font-weight: 600;
}

.email {
  color: #333;
  font-size: 12px;
  font-weight: 400;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 3px 10px;
  color: rgba(55, 53, 47, 0.85);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.selected {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 3px 10px;
  color: #202124;
  font-weight: 500;
  border-radius: 4px;
  background: #fff;
}
.menuItem:hover {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 3px 10px;
  color: #202124;
  font-weight: 500;
  border-radius: 4px;
  background: #fff;
}
