.heading {
  color: #222;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.inputContainer {
  border-radius: 8px !important;
  border: 1px solid #808080 !important;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1) !important;
  height: 43px;
}

.optionsContainer {
  display: flex;
  width: 100%;
  padding: 14px 10px;

  justify-content: space-between;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  background-color: #fafafa;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px !important;
  border: 1px solid #808080;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(45px) !important;
}

.optionsContainerUpdated {
  display: flex;
  width: 100%;
  padding: 9px 10px;

  justify-content: space-between;
  align-items: center;
  gap: 2px;
  /* border-radius: 8px; */
  /* border: 1px solid #d9d9d9; */
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  /* font-family: Inter; */
  /* font-style: normal; */
  /* background-color: #fafafa; */
  font-weight: 400;
  /* line-height: normal; */
  /* border-radius: 8px !important; */
  border: 1px solid #d9d9d9;
  /* box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(45px) !important; */
}

.optionsContainer:hover {
  border-color: #176fff;
}

.error {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: red;
}

.emailContainer {
  margin-top: 20px;
  border-radius: 9px;
  background: #fddd99;
  padding: 10px 15px;
}

.emailHeading {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.emailButton {
  border: 1px solid #808080 !important;
  color: #222 !important;
}
